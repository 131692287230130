import React, { useState, useEffect } from "react";
import {
  Route,
  Routes,
  NavLink,
  Link,
  useLocation,
  Outlet,
} from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import cx from "classnames";
import logoIcon from "./img/logo_GMX.svg";
import darkLogoIcon from "./img/logo_GMX_dark.svg";
import lightLogoIcon from "./img/logo_GMX_light.svg";
import { FaSun, FaMoon, FaTimes } from "react-icons/fa";
import { FiX } from "react-icons/fi";
import { RiMenuLine } from "react-icons/ri";

import "./App.css";

const Traders = React.lazy(() => import("./views/Traders"));
const Badges = React.lazy(() => import("./views/Badges"));
const Pulse = React.lazy(() => import("./views/Pulse"));
const Activities = React.lazy(() => import("./views/Activities"));

function AppHeaderLinks({ mode, small, clickCloseIcon }) {
  return (
    <div className="App-header-links">
      {small && (
        <div className="App-header-links-header">
          <div
            className="App-header-menu-icon-block"
            onClick={() => clickCloseIcon()}
          >
            <FiX className="App-header-menu-icon" />
          </div>
          <NavLink
            className="App-header-link-main"
            to=""
            style={{ color: "white", textDecoration: "none" }}
          >
            <img
              height="40"
              src="/favicon.png"
              style={{ marginRight: "10px" }}
            />
            PHAME.IO V2
          </NavLink>
        </div>
      )}
      <div className="App-header-link-container">
        <NavLink to="/badges" exact className="nav-link">
          Badges
        </NavLink>
        <NavLink to="/traders" exact className="nav-link">
          Traders
        </NavLink>
        <a
          href="https://dapp.phame.io/"
          target="_blank"
          className="nav-link"
          rel="noreferrer"
        >
          APP
        </a>
        <a
          href="https://ph-defi.gitbook.io/phame-protocol"
          target="_blank"
          className="nav-link"
          rel="noreferrer"
        >
          DOCS
        </a>
      </div>
    </div>
  );
}

export default function App() {
  const location = useLocation();
  const [mode, setMode] = useState(null);
  const [isDrawerVisible, setIsDrawerVisible] = useState(undefined);

  const slideVariants = {
    hidden: { x: "-100%" },
    visible: { x: 0 },
  };

  const fadeVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  useEffect(() => {
    const savedMode = window.localStorage.getItem("mode");
    const targetMode = savedMode == "light" ? "light" : "dark";
    document.querySelector("body").style.backgroundColor =
      targetMode == "dark" ? "#101124" : "#f6f9ff";
    setMode(targetMode);
  }, []);

  const switchMode = () => {
    const targetMode = mode == "dark" ? "light" : "dark";
    window.localStorage.setItem("mode", targetMode);
    document.querySelector("body").style.backgroundColor =
      targetMode == "dark" ? "#101124" : "#f6f9ff";
    setMode(targetMode);
  };

  return (
    <div className={cx("App", mode)}>
      {isDrawerVisible && (
        <AnimatePresence>
          {isDrawerVisible && (
            <motion.div
              className="App-header-backdrop"
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={fadeVariants}
              transition={{ duration: 0.2 }}
              onClick={() => setIsDrawerVisible(!isDrawerVisible)}
            ></motion.div>
          )}
        </AnimatePresence>
      )}
      <div className="nav">
        <div className="nav-left">
          <div
            className="App-header-menu-icon-block"
            onClick={() => setIsDrawerVisible(!isDrawerVisible)}
          >
            {!isDrawerVisible && (
              <RiMenuLine className="App-header-menu-icon" />
            )}
            {isDrawerVisible && <FaTimes className="App-header-menu-icon" />}
          </div>
          <a href="/" className="nav-logo">
            <img
              height="40"
              src="/favicon.png"
              style={{ marginRight: "10px" }}
            />
            PHAME.IO V2
          </a>
        </div>
        <div className="nav-right">
          <NavLink to="/badges" className="nav-link hidden-when-small">
            Badges
          </NavLink>
          <NavLink to="/traders" className="nav-link hidden-when-small">
            Traders
          </NavLink>
          <a
            href="https://dapp.phame.io/"
            target="_blank"
            className="nav-link"
            rel="noreferrer"
          >
            APP
          </a>
          <a
            href="https://ph-defi.gitbook.io/phame-protocol"
            target="_blank"
            className="nav-link"
            rel="noreferrer"
          >
            DOCS
          </a>
          <div className="modeselect" onClick={() => switchMode()}>
            {mode == "dark" ? <FaSun /> : <FaMoon />}
          </div>
        </div>
      </div>
      <AnimatePresence>
        {isDrawerVisible && (
          <motion.div
            onClick={() => setIsDrawerVisible(false)}
            className="App-header-links-container App-header-drawer"
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={slideVariants}
            transition={{ duration: 0.2 }}
          >
            <AppHeaderLinks
              mode={mode}
              small
              clickCloseIcon={() => setIsDrawerVisible(false)}
            />
          </motion.div>
        )}
      </AnimatePresence>
      <div className="content">
        <Routes>
          <Route
            path="traders"
            element={
              <React.Suspense fallback={<>...</>}>
                <Traders />
              </React.Suspense>
            }
          />
          <Route
            path="activities/:id"
            element={
              <React.Suspense fallback={<>...</>}>
                <Activities />
              </React.Suspense>
            }
          />
          <Route
            path="badges"
            element={
              <React.Suspense fallback={<>...</>}>
                <Badges />
              </React.Suspense>
            }
          />
          <Route
            path="*"
            element={
              <React.Suspense fallback={<>...</>}>
                <Pulse mode={mode}></Pulse>
              </React.Suspense>
            }
          />
        </Routes>
      </div>
    </div>
  );
}
